<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton :classProp="'secondary'" @vasionButtonClicked="goToBarcodeRecognitionList()">
          Cancel
        </VasionButton>
        <VasionButton class="last-btn" :classProp="'primary'" :isDisabled="!isDirty" @vasionButtonClicked="save()">
          Save
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <VasionSnackbar
      id="edit-barcode-recognition-snackbar"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <AutomationEngineSharedHeader
      ref="frequencyComponent"
      nameLabel="Name"
      @permissions="showMainSection = false"
      @general-automation="showMainSection = true"
      @dirty="isDirty = true"
    />
    <div v-if="showMainSection" id="build-barcode-recognition" class="collapsable-section">
      <VasionButton
        name="collapse-section"
        class="collapse-section"
        :icon="showBuildBarcodeRecognition ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
        title="Collapse Section"
        @vasionButtonClicked="showBuildBarcodeRecognition = !showBuildBarcodeRecognition"
      />
      <h2 class="subheader">
        Build Barcode Recognition
      </h2>
    </div>
    <div v-if="showMainSection" v-show="showBuildBarcodeRecognition">
      <div class="row">
        <VasionInput
          id="process-folder"
          v-model="selectedProcessFolder"
          :readonly="true"
          title="PROCESS FOLDER"
          inputType="top-white"
          name="process-folder"
          placeholder="Select Folder..."
          :required="true"
          :width="'306'"
          :isInErrorState="processFolderError"
          @input="isDirty = true"
        />
        <VasionButton
          id="process-folder-button"
          class="browse-button"
          :classProp="'primary'"
          @vasionButtonClicked="toggleBrowseFolderDialog('process-folder')"
        >
          Browse
        </VasionButton>

        <VasionCheckbox
          id="include-subfolders"
          name="include-subfolders"
          class="row-checkbox"
          style="paddingTop: 19px"
          :checked="includeSubfolders"
          @change="includeSubfolders = !includeSubfolders; isDirty = true"
        >
          Include Subfolders
        </VasionCheckbox>
      </div>
      <div class="row">
        <VasionCheckbox
          id="move-completed-documents-checkbox"
          name="move-completed-documents-checkbox"
          class="row-checkbox"
          :checked="moveCompletedDocuments"
          @change="moveCompletedDocuments = !moveCompletedDocuments; isDirty = true"
        >
          Move Completed Documents
        </VasionCheckbox>
      </div>
      <div class="row">
        <VasionInput
          id="completed-documents-folder"
          v-model="selectedMoveToFolder"
          title="COMPLETED DOCUMENTS FOLDER"
          :readonly="true"
          inputType="top-white"
          name="completed-documents-folder"
          placeholder="Select Folder..."
          :width="'306'"
          :isDisabled="!moveCompletedDocuments"
          @input="isDirty = true"
        />
        <VasionButton
          id="completed-documents-folder-button"
          class="browse-button"
          :classProp="'primary'"
          :isDisabled="!moveCompletedDocuments"
          @vasionButtonClicked="toggleBrowseFolderDialog('move-to-folder')"
        >
          Browse
        </VasionButton>
      </div>
      <div class="row">
        <VasionDropList
          v-slot="slotItem"
          v-model="attributeForm"
          :dataArray="attributeFormList"
          width="392"
          :title="`${$formsLabel.toUpperCase()}`"
          placeholder="..."
          type="plain-list"
          displayName="name"
          valueName="value"
          @input="isDirty = true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div class="row">
        <VasionCheckbox
          id="use-barcode-splitter"
          name="use-barcode-splitter"
          class="row-checkbox"
          :checked="useBarcodeSplitter"
          :isDisabled="useBarcodeSplitterDisabled"
          @change="useBarcodeSplitter= !useBarcodeSplitter; isDirty = true"
        >
          Use Barcode Splitter
        </VasionCheckbox>
        <VasionCheckbox
          id="read-barcode"
          name="read-barcode"
          class="row-checkbox"
          :isDisabled="readBarcodeDisabled"
          :checked="readBarcode"
          @change="readBarcode= !readBarcode; isDirty = true"
        >
          Read Barcode
        </VasionCheckbox>
        <VasionCheckbox
          id="delete-original-documents"
          name="delete-original-documents"
          class="row-checkbox"
          :checked="deleteOriginalDocuments"
          :isDisabled="deleteOriginalDocumentsDisabled"
          @change="deleteOriginalDocuments= !deleteOriginalDocuments; isDirty = true"
        >
          Delete Original Documents
        </VasionCheckbox>
        <VasionCheckbox
          id="create-new-document"
          name="create-new-document"
          class="row-checkbox"
          :checked="createNewDocument"
          :isDisabled="createNewDocumentDisabled"
          @change="createNewDocument= !createNewDocument; isDirty = true"
        >
          Create New Document
        </VasionCheckbox>
      </div>
      <div class="row">
        <div class="row">
          <VasionCheckbox
            id="burst-on-page-number"
            name="burst-on-page-number"
            :checked="burstOnPageNumber"
            :isDisabled="burstOnPageNumberDisabled"
            style="padding-right: 10px"
            @change="burstOnPageNumber = !burstOnPageNumber; isDirty = true"
          >
            Burst on Page Number:
          </VasionCheckbox>
          <VasionInput
            id="burst-on-page-number-value"
            v-model="burstOnPageNumberValue"
            inputType="top-white"
            name="burst-on-page-number-value"
            specialType="number"
            style="padding-right: 13px"
            :isDisabled="!burstOnPageNumber"
            min="0"
            :width="'187'"
            @input="isDirty = true"
          />
        </div>
        <VasionCheckbox
          id="read-first-page-only"
          name="read-first-page-only"
          class="row-checkbox"
          :checked="readFirstPageOnly"
          :isDisabled="readFirstPageOnlyDisabled"
          @change="readFirstPageOnly= !readFirstPageOnly; isDirty = true"
        >
          Read First Page Only
        </VasionCheckbox>
        <VasionCheckbox
          id="keep-barcode-page"
          name="keep-barcode-page"
          class="row-checkbox"
          :checked="keepBarcodePage"
          :isDisabled="disableBarcodeCheckboxesForCountyBatch"
          @change="keepBarcodePage= !keepBarcodePage; isDirty = true"
        >
          Keep Barcode Page
        </VasionCheckbox>
        <VasionCheckbox
          id="use-barcode-as-value-name"
          name="use-barcode-as-value-name"
          class="row-checkbox"
          :checked="useBarcodeValueAsName"
          :isDisabled="disableBarcodeCheckboxesForCountyBatch"
          @change="useBarcodeValueAsName= !useBarcodeValueAsName; isDirty = true"
        >
          Use Barcode as Value Name
        </VasionCheckbox>
      </div>
      <div class="row">
        <VasionCheckbox
          id="barcode-on-last-page"
          name="barcode-on-last-page"
          class="row-checkbox"
          :checked="barcodeOnLastPage"
          :isDisabled="barcodeOnLastPageDisabled"
          @change="barcodeOnLastPage = !barcodeOnLastPage; isDirty = true"
        >
          Barcode on Last Page
        </VasionCheckbox>
        <VasionCheckbox
          v-if="featureFlagBarcodeBatchScanning"
          id="barcode-batch-scanning"
          name="barcode-batch-scanning"
          class="import-checkbox"
          :checked="barcodeBatchScanning"
          :isDisabled="barcodeBatchScanningPhase2"
          @change="toggleBarcodeBatchScanning(); toggleCheckbox('barcodeBatchScanning');"
        >
          County Batch Scanning
        </VasionCheckbox>
      </div>
      <div
          v-if="featureFlagBarcodeBatchScanning"
          class="row"
        >
          <VasionCheckbox
            id="county-batch-scanning-phase2"
            name="county-batch-scanning-phase2"
            class="import-checkbox"
            :checked="barcodeBatchScanningPhase2"
            :isDisabled="barcodeBatchScanning || !attributeForm"
            @change="toggleBarcodeBatchScanning(); toggleCheckbox('barcodeBatchScanningPhase2');"
          >
            County Batch Scanning Phase 2
          </VasionCheckbox>
        </div>
        <div v-if="barcodeBatchScanningPhase2" class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="scanningLookupPhase2"
            class="row-field"
            :dataArray="lookupFieldListFiltered"
            displayName="name"
            valueName="id"
            title="Scanning Lookup"
            placeholder="Select Lookup..."
            type="plain-list"
            @input="isDirty = true"
          >
            {{slotItem.item.name}}
          </VasionDropList>
        </div>

      <div class="row">
        <VasionDropList
          v-slot="slotItem"
          v-model="barcodeIndex"
          class="input-padding"
          :dataArray="filteredBarcodeList"
          width="392"
          title="Barcode Index"
          placeholder="Select Index..."
          type="plain-list"
          displayName="name"
          valueName="value"
          @input="isDirty = true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <VasionInput
          id="prefix"
          v-model="prefix"
          class="input-padding"
          inputType="top-white"
          name="prefix"
          title="Prefix"
          width="392"
          placeholder="Enter Prefix..."
          :isDisabled="disableBarcodeCheckboxesForCountyBatch"
          @input="isDirty = true"
        />
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedSplitterOption"
          class="input-padding"
          :dataArray="splitterOptions"
          width="392"
          title="Splitter Option"
          placeholder="Select Option..."
          type="plain-list"
          displayName="name"
          valueName="value"
          :isDisabled="disableBarcodeCheckboxesForCountyBatch"
          @input="isDirty = true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
      <div class="row">
        <div class="column">
          <div v-for="(value, index) of splitterValues" :key="index">
            <div style="display: flex; flex-direction: row; margin-bottom: 10px;">
              <VasionInput
                id="value-from-input"
                v-model="value.message"
                class="input-padding"
                inputType="top-white"
                name="value-from-input"
                width="392"
                placeholder="Enter Value..."
                :isDisabled="splitterValuesDisabled"
                :title="index === 0 ? 'Value(s)' : ''"
                @input="isDirty = true"
              />
              <div
                v-if="index !== 0"
                class="minus-button"
                :class="{ 'plus-and-minus-disabled': splitterValuesDisabled }"
                @click="removeValue(index)"
              >
                <VasionRemoveIcon />
              </div>
              <div
                v-if="index === 0"
                class="plus-button"
                :class="{ 'plus-and-minus-disabled': splitterValuesDisabled }"
                @click="pushValue()"
              >
                <VasionAddIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <VasionInput
          id="value-from-input"
          v-model="barcodeRangeFrom"
          class="input-padding"
          inputType="top-white"
          name="value-from-input"
          title="Value From"
          width="188"
          specialType="number"
          min="0"
          :isDisabled="barcodeRangeFromAndToDisabled"
          placeholder="Enter Value..."
          @input="isDirty = true"
        />
        <VasionInput
          id="value-to-input"
          v-model="barcodeRangeTo"
          class="input-padding"
          inputType="top-white"
          name="value-to-input"
          title="Value To"
          width="188"
          min="0"
          specialType="number"
          :isDisabled="barcodeRangeFromAndToDisabled"
          placeholder="Enter Value..."
          @input="isDirty = true"
        />
      </div>
    </div>
    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'CONFIRM'"
      :modalType="'slot'"
      :sync="showBrowseFolder"
      @confirmButtonClick="folderDialogOK()"
      @noButtonClick="showBrowseFolder = false"
    >
      <div id="browse-folders-container">
        <VasionFolders @itemSelected="folderSelected = true" />
      </div>
    </VasionGeneralModal>
    <Loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
    </div>
  </div>
</template>

<script>
import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'EditBarcodeRecognition',
  components: {
    AutomationEngineSharedHeader,
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data() {
    return {
      attributeForm: '',
      barcodeIndex: { value: null },
      barcodeList: [],
      barcodeBatchScanning: false,
      barcodeBatchScanningPhase2: false,
      barcodeOnLastPage: false,
      barcodeRangeFrom: 0,
      barcodeRangeTo: 0,
      burstOnPageNumber: false,
      burstOnPageNumberValue: 0,
      createNewDocument: false,
      deleteOriginalDocuments: false,
      featureFlagBarcodeBatchScanning: false,
      fileBrowseField: '',
      folderSelected: false,
      includeSubfolders: false,
      indexForms: [],
      isDirty: false,
      isLoading: false,
      keepBarcodePage: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      moveCompletedDocuments: false,
      prefix: '',
      processFolderError: false,
      readBarcode: false,
      readFirstPageOnly: false,
      scanningLookupPhase2: {},
      schedulerId: 0,
      schedulerTitle: '',
      selectedMoveToFolder: '',
      selectedMoveToFolderId: null,
      selectedProcessFolder: '',
      selectedProcessFolderId: null,
      selectedSplitterOption: '',
      showBrowseFolder: false,
      showBuildBarcodeRecognition: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      splitterOptions: [
        { name: 'Range Value', value: 0 },
        { name: 'Start With', value: 1 },
        { name: 'Values', value: 2 },
        { name: 'Advance Barcode', value: 3 },
        { name: 'Value Changed', value: 4 },
      ],
      splitterValues: [{ message: '' }],
      useBarcodeSplitter: false,
      useBarcodeValueAsName: false,
    }
  },
  computed: {
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    barcodeOnLastPageDisabled() { return !this.useBarcodeSplitter || this.disableBarcodeCheckboxesForCountyBatch },
    barcodeRangeFromAndToDisabled() { return this.selectedSplitterOption?.name !== 'Range Value' },
    burstOnPageNumberDisabled() { return this.useBarcodeSplitter || this.readBarcode || this.readFirstPageOnly || this.disableBarcodeCheckboxesForCountyBatch },
    createNewDocumentDisabled() { return this.useBarcodeSplitter || this.readBarcode || this.readFirstPageOnly || this.burstOnPageNumber || this.disableBarcodeCheckboxesForCountyBatch },
    deleteOriginalDocumentsDisabled() { return this.burstOnPageNumber || this.disableBarcodeCheckboxesForCountyBatch },
    disableBarcodeCheckboxesForCountyBatch() {
      return this.barcodeBatchScanning || this.barcodeBatchScanningPhase2
    },
    filteredBarcodeList() {
      const selectedBarcode = this.barcodeList.filter(barcode => barcode.IndexFormID === this.attributeForm?.value)
      if (!selectedBarcode) {
        return [{}]
      }
      return selectedBarcode.map(barcode => { return { name: barcode.ConfigName, value: barcode.ID } })
    },
    lookupFieldList() {
      return this.$store.state.fieldMapping.lookupList
    },
    lookupFieldListFiltered() {
      return this.lookupFieldList.filter(element => {
        return element.attributeFormID === this.attributeForm.value
      })
    },
    readBarcodeDisabled() { return this.useBarcodeSplitter || this.disableBarcodeCheckboxesForCountyBatch },
    readFirstPageOnlyDisabled() { return this.useBarcodeSplitter || this.disableBarcodeCheckboxesForCountyBatch },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    splitterValuesDisabled() { return this.selectedSplitterOption?.name !== 'Start With' && this.selectedSplitterOption?.name !== 'Values' },
    useBarcodeSplitterDisabled() { return this.burstOnPageNumber || this.readBarcode || this.readFirstPageOnly || this.disableBarcodeCheckboxesForCountyBatch},
  },
  watch: {
    attributeForm(newVal) {
      if (newVal === ''){
        this.barcodeBatchScanningPhase2 = false
        this.scanningLookupPhase2 = {}
      }
    },
    barcodeOnLastPageDisabled(val) { if (val) { this.barcodeOnLastPage = false } },
    burstOnPageNumberDisabled(val) { if (val) { this.burstOnPageNumberValue = 0; this.burstOnPageNumber = false } },
    createNewDocumentDisabled(val) { if (val) { this.createNewDocument = false } },
    deleteOriginalDocumentsDisabled(val) { if (val) { this.deleteOriginalDocuments = false } },
    readFirstPageOnlyDisabled(val) { if (val) { this.readFirstPageOnly = false } },
    useBarcodeSplitterDisabled(val) { if (val) { this.useBarcodeSplitter = false } },
  },
  async created() {
    this.isLoading = true
    const promises = await Promise.all([
      this.$store.dispatch('capture/getAllBarcodeIndex'),
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('fieldMapping/getDatabaseLookups', true),
    ])
    // eslint-disable-next-line prefer-destructuring
    this.barcodeList = promises[0]
    // eslint-disable-next-line prefer-destructuring
    this.indexForms = promises[1]
    // eslint-disable-next-line prefer-destructuring
    this.featureFlagBarcodeBatchScanning = this.$store.state.systemSettings.featureFlags.barcodeBatchScanning
    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      const savedData = await this.$store.dispatch('automationEngine/getBarcodeRecognitionScheduler', parseInt(this.$route.params.schedulerId, 10))
      this.setHeaderDetails(savedData?.runDetails)
      this.schedulerTitle = savedData?.runDetails?.name
      await this.setSchedulerValues(savedData)
    } else {
      this.schedulerTitle = 'Untitled Schedule Barcode'
    }
    this.isLoading = false
  },
  methods: {
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ name: this.routeTo.name })
    },
    folderDialogOK() {
      if (this.selectedFolder && this.selectedFolder.item.FolderId > 0 && this.selectedFolder.isSelected === true) {
        if (this.fileBrowseField === 'process-folder') {
          this.selectedProcessFolderId = this.selectedFolder.item.FolderId
          this.selectedProcessFolder = this.selectedFolder.item.Name
        } else if (this.fileBrowseField === 'move-to-folder') {
          this.selectedMoveToFolderId = this.selectedFolder.item.FolderId
          this.selectedMoveToFolder = this.selectedFolder.item.Name
        }
      } else {
        this.selectedProcessFolderId = null
        this.selectedProcessFolder = ''

        this.selectedMoveToFolderId = null
        this.selectedMoveToFolder = ''
      }

      this.showBrowseFolder = false
      this.$store.dispatch('common/setSelectedFolder', null)
    },
    goToBarcodeRecognitionList() { this.$router.push({ name: 'BarcodeRecognition' }) },
    pushValue() {
      if (!this.splitterValuesDisabled) {
        this.splitterValues.push({})
      }
    },
    removeValue(index) {
      if (!this.splitterValuesDisabled) {
          this.splitterValues.splice(index, 1);
      }
    },
    async save() {
      this.isLoading = true
      if (this.selectedProcessFolderId === null) {
        this.showBuildBarcodeRecognition = true
        this.processFolderError = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Please Select a Process Folder'
        this.snackbarImage = false
        this.showSnackbarBool = true
        this.isLoading = false
        return
      }
      const { frequencyComponent } = this.$refs
      await frequencyComponent.validate()
      if (frequencyComponent.errors !== '') {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = frequencyComponent.errors
        this.snackbarImage = false
        this.showSnackbarBool = true
        this.isLoading = false
        return
      }
      if (this.validate()) {
        const payload = {
          runDetails: await frequencyComponent.getValues(),
          objectID: this.attributeForm?.value,
          folderID: this.selectedProcessFolderId,
          includeSubfolders: this.includeSubfolders,
          moveCompletedDocumentsFolderID: this.moveCompletedDocuments ? this.selectedMoveToFolderId : 0,
          newDocumentsOnly: this.newDocumentsOnly,
          createNewDocument: this.createNewDocument,
          barcodeSplitter: this.useBarcodeSplitter,
          readBarcode: this.readBarcode,
          deleteOriginalDocuments: this.deleteOriginalDocuments,
          burstOnPageNumber: this.burstOnPageNumber ? this.burstOnPageNumberValue : null,
          readFirstPageOnly: this.readFirstPageOnly,
          keepBarcodePage: this.keepBarcodePage,
          barcodeOnLastPage: this.barcodeOnLastPage,
          useBarcodeValueAsName: this.useBarcodeValueAsName,
          barcodeIndexID: this.barcodeIndex?.value,
          prefix: this.prefix,
          splitterOption: this.selectedSplitterOption?.name,
          splitterValues: this.splitterValues.map(value => value.message),
          barcodeRangeFrom: this.barcodeRangeFrom,
          barcodeRangeTo: this.barcodeRangeTo,
          countyBatchScanning: this.barcodeBatchScanning,
          countyBatchScanningPhase2: this.barcodeBatchScanningPhase2,
          scanningLookupPhase2: this.scanningLookupPhase2.id,
        }
        const response = await this.$store.dispatch('automationEngine/saveBarcodeRecognitionScheduler', payload)
        this.isLoading = false
        if (response > 0) {
          this.snackbarTitle = 'Success'
          this.snackbarSubTitle = 'Barcode Recognition Scheduled'
          this.snackbarImage = true
          this.showSnackbarBool = true
          this.isDirty = false
          setTimeout(() => {
            this.goToBarcodeRecognitionList()
          }, 1500)
          return
        }
      }
      this.snackbarTitle = 'Error'
      this.snackbarImage = false
      this.showSnackbarBool = true
      this.isLoading = false
    },
    setHeaderDetails(details) {
      const { frequencyComponent } = this.$refs
      frequencyComponent.setValues(details)
    },
    async setSchedulerValues(savedData) {
      this.selectedProcessFolder = savedData?.folderName
      this.attributeForm = await this.attributeFormList?.find(a => a?.value === savedData?.objectID) || null
      this.barcodeBatchScanning = savedData?.countyBatchScanning
      this.barcodeBatchScanningPhase2 = savedData?.countyBatchScanningPhase2
      this.barcodeIndex = this.filteredBarcodeList?.find(a => a?.value === savedData?.barcodeIndexID) || null
      this.barcodeOnLastPage = savedData?.barcodeOnLastPage
      this.barcodeRangeFrom = savedData?.barcodeRangeFrom
      this.barcodeRangeTo = savedData?.barcodeRangeTo
      this.useBarcodeSplitter = savedData?.barcodeSplitter
      this.burstOnPageNumber = savedData?.burstOnPageNumber > 0
      this.burstOnPageNumberValue = savedData?.burstOnPageNumber
      this.createNewDocument = savedData?.createNewDocument
      this.deleteOriginalDocuments = savedData?.deleteOriginalDocuments
      this.keepBarcodePage = savedData?.keepBarcodePage
      this.prefix = savedData?.prefix
      this.moveCompletedDocuments = savedData?.moveCompletedDocumentsFolderID > 0
      this.selectedMoveToFolderId = savedData?.moveCompletedDocumentsFolderID
      this.selectedMoveToFolder = savedData?.moveCompletedDocumentsFolderName
      this.newDocumentsOnly = savedData?.newDocumentsOnly
      this.readBarcode = savedData?.readBarcode
      this.readFirstPageOnly = savedData?.readFirstPageOnly
      this.selectedSplitterOption = this?.splitterOptions.find(option => { return option?.name === savedData?.splitterOption })
      this.splitterValues = savedData?.splitterValues.map(value => { return { message: value } })
      this.useBarcodeValueAsName = savedData?.useBarcodeValueAsName
      this.includeSubfolders = savedData?.includeSubfolders
      this.selectedProcessFolderId = savedData?.folderID
      this.scanningLookupPhase2 = this.lookupFieldList.find(element => element.id === savedData?.scanningLookupPhase2) || {}
      this.isLoading = false
    },
    toggleBarcodeBatchScanning()
    {
      this.useBarcodeSplitter = false
      this.readBarcode = false
      this.deleteOriginalDocuments = false
      this.createNewDocument = false
      this.burstOnPageNumber = false
      this.burstOnPageNumberValue = 0
      this.readFirstPageOnly = false
      this.keepBarcodePage = false
      this.useBarcodeValueAsName = false
      this.barcodeOnLastPage = false
      this.prefix = ''
      this.selectedSplitterOption = ''
      this.barcodeRangeFrom = 0
      this.barcodeRangeTo = 0
      this.scanningLookupPhase2 = {}
    },
    toggleBrowseFolderDialog(field) {
      this.folderSelected = false
      this.showBrowseFolder = !this.showBrowseFolder

      this.fileBrowseField = ''
      if (this.showBrowseFolder && field && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
      this.isDirty = true
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    validate() {
      if (this.barcodeRangeFrom < 0 || this.barcodeRangeTo < 0) {
        this.snackbarSubTitle += '"Value From" and "Value To" must not be negative.\n'
        return false
      } else if (this.burstOnPageNumber && this.burstOnPageNumberValue < 0) {
        this.snackbarSubTitle += '"Burst on Page Number" must not be negative.\n'
        return false
      }
      return true
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .collapsable-section {
    display: flex;
    flex-direction: row;

    .subheader {
      @include SubHeadline;
      display: block;
      float: left;
      margin-top: 5px;
    }
  }

    .collapse-section {
      background-color: $grey-50;
      border-radius: 8px;
      width: 32px;
      height: 32px;
      margin: 0 16px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .collapse-section:hover {
      border-radius: 8px;
      background-color: $grey-200;
    }

    .row{
      display: flex;
      flex-direction: row;
      margin: 0px;
      flex-wrap: wrap;
      padding-top: 12px;
      padding-bottom: 12px;
      align-items: center;
    }

    .column{
      display: flex;
      flex-direction: column;
    }

    .row::after,
    .row::before {
      display: none;
    }

    .browse-button {
      padding-top: 20px;
    }

    #browse-folders-container {
      width: 400px;
      height: 300px;
      overflow: auto;
    }

    .row-checkbox {
      width: 396px;
    }

    .plus-button, .minus-button {
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 8px;
      background-color: $grey-50;
      align-self: start;
    }

    .plus-button {
      position: relative;
      top: 20px;
    }

    .plus-and-minus-disabled {
      background-color: $grey-75;
      fill: $grey-300;
    }

    .plus-button:hover, .minus-button:hover{
      cursor: pointer;
    }
    .plus-and-minus-disabled:hover {
      cursor: default;
    }

    .input-padding {
      padding-right: 15px;
    }

</style>
